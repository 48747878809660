import { useState } from 'react';

import { RootState } from '@redux/Store';
import { useSelector, useDispatch } from 'react-redux';

import { FetchSendRating } from '@redux/Query/FetchSendRating';

import MiniPanel from '@res/MiniPanel';
import Rating from '@res/Rating';
import Button from '@res/Button';

const Complete: React.FC = () => {
    const ratings = ['Ужасно', 'Плохо', 'Приемлемо', 'Хорошо', 'Отлично'];
    const orderId = useSelector(
        (state: RootState) => state.orderActive.data?.id
    );
    const [rating, setRating] = useState<number>(0);

    const dispatch = useDispatch();

    const handleRatingChange = (value: number) => {
        setRating(value);
    };

    const handleClickSave = () => {
        if (orderId) {
            FetchSendRating(dispatch, orderId, rating);
        }
    };

    return (
        <div className='space-y-4'>
            <MiniPanel>
                <div className='flex justify-center'>
                    <Rating value={rating} onChange={handleRatingChange} />
                </div>
                {rating > 0 && (
                    <div className='text-center'>{ratings[rating - 1]}</div>
                )}
            </MiniPanel>
            {rating > 0 && <Button onClick={handleClickSave}>Отправить</Button>}
        </div>
    );
};

export default Complete;
