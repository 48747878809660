import { AppDispatch } from '@redux/Store';
import api from '@redux/Api/Api';
import {
    setActiveOrder,
    setLoading,
    setData,
} from '@redux/Slice/OrderActiveSlice';

export const FetchSendRating = async (
    dispatch: AppDispatch,
    order_id: number,
    rating: number
) => {
    try {
        dispatch(setLoading(true));
        const response = await api.post('order_archive/set_rating', {
            order_id: order_id,
            rating: rating,
        });
        if (response.status === 200) {
            dispatch(setActiveOrder(false));
            dispatch(setData(null));
            return;
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        dispatch(setLoading(false));
    }
};
