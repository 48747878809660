import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/Store';

import Panel from '@res/Panel';
import CreateOrder from './CreateOrder/CreateOrder';

import { OrderActiveStatus } from '@enum/OrderActiveStatus';
import Payment from './Payment/Payment';
import WaitingExecutor from './WaitExecutor/WaitExecutor';
import SearchExecutor from './SearchExecutor/SearchExecutor';
import OnWayExecutor from './OnWayExecutor/OnWayExecutor';
import OnSiteExecutor from './OnSiteExecutor/OnSiteExecutor';
import Error from './Error/Error';
import Rating from './Complete/Complete';
import ProgressBar from '@img/ProgressBar';
import RegionNotServed from './RegionNotServed/RegionNotServed';

const Left: React.FC = () => {
    const isAuth = useSelector((state: RootState) => state.user.isAuth);
    const isData = useSelector((state: RootState) => state.map.data);
    const isLoading = useSelector((state: RootState) => state.map.isLoading);
    const isOrderActive = useSelector(
        (state: RootState) => state.orderActive.isActiveOrder
    );
    const orderActive = useSelector(
        (state: RootState) => state.orderActive.data
    );

    let title = '';
    let content: ReactNode = null;

    const createOrder = () => {
        title = 'Создание заявки';
        if (isLoading) {
            content = (
                <div className='flex justify-center'>
                    <ProgressBar />
                </div>
            );
            return;
        }
        if (isData) {
            content = <CreateOrder />;
            return;
        } else {
            content = <RegionNotServed />;
        }
    };

    const payment = () => {
        title = 'Оплата';
        content = <Payment />;
    };

    const searchExecutor = () => {
        title = 'Поиск исполнителя';
        content = <SearchExecutor />;
    };

    const waitingExecutor = () => {
        title = 'Ожидание исполнителя';
        content = <WaitingExecutor />;
    };

    const onWayExecutor = () => {
        title = 'Исполнитель в пути';
        content = <OnWayExecutor />;
    };

    const onSiteExecutor = () => {
        title = 'Исполнитель на месте';
        content = <OnSiteExecutor />;
    };

    const onRating = () => {
        title = 'Оцените исполнителя';
        content = <Rating />;
    };

    const error = () => {
        title = 'Ошибка';
        content = <Error />;
    };

    if (!isAuth || !isOrderActive) {
        createOrder();
    }

    if (orderActive) {
        switch (orderActive.status) {
            case OrderActiveStatus.PAYMENT:
                payment();
                break;
            case OrderActiveStatus.SEARCH_EXECUTOR:
                searchExecutor();
                break;
            case OrderActiveStatus.WAIT_EXECUTOR:
                waitingExecutor();
                break;
            case OrderActiveStatus.ON_WAY_EXECUTOR:
                onWayExecutor();
                break;
            case OrderActiveStatus.ON_SITE_EXECUTOR:
                onSiteExecutor();
                break;
            case OrderActiveStatus.COMPLETE:
                onRating();
                break;
            default:
                error();
                break;
        }
    }

    return (
        <Panel className='absolute left-4 top-24 h-auto w-80' title={title}>
            <div className='m-4 space-y-4'>{content}</div>
        </Panel>
    );
};

export default Left;
