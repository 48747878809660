import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Passport {
    first_name: string | null;
    last_name: string | null;
    middle_name: string | null;
}

interface Auto {
    auto: string | null;
    auto_number: string | null;
}

interface Service {
    id: number;
    name: string;
    parameters: Parameters[];
}

interface Parameters {
    name: string;
    tag: string;
    value: number;
}

interface Executor {
    id: number;
    lat: number;
    lon: number;
    passport: Passport | null;
    auto: Auto | null;
    service: Service | null;
}

interface Tariff {
    additional_field: number;
    id: number;
    name: string;
    price: string;
    service: string;
}

interface Data {
    address: string;
    city: string;
    comment: string;
    date_create: string;
    distance_km: number | null;
    executor: Executor | null;
    id: number;
    lat: number;
    lon: number;
    parameters: any[];
    payment: string;
    pending: any;
    price: string;
    status: string;
    tariff: Tariff;
}

interface Cancel {
    isEnable: boolean;
    comment: string;
}

interface State {
    isActiveOrder: boolean;
    isLoading: boolean;
    data: Data | null;
    cancel: Cancel;
}

const initialState: State = {
    isActiveOrder: false,
    isLoading: false,
    data: null,
    cancel: {
        isEnable: false,
        comment: '',
    },
};

const orderActiveSlice = createSlice({
    name: 'orderActiveSlice',
    initialState,
    reducers: {
        setActiveOrder: (state, action: PayloadAction<boolean>) => {
            state.isActiveOrder = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setData: (state, action: PayloadAction<Data | null>) => {
            state.data = action.payload;
        },
        setCancelEnable: (state, action: PayloadAction<boolean>) => {
            state.cancel.isEnable = action.payload;
        },
        setComment: (state, action: PayloadAction<string>) => {
            state.cancel.comment = action.payload;
        },
    },
});

export const {
    setActiveOrder,
    setLoading,
    setData,
    setCancelEnable,
    setComment,
} = orderActiveSlice.actions;
export default orderActiveSlice.reducer;
