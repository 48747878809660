interface ImageProps {
    children: any;
}

const Image: React.FC<ImageProps> = ({ children }) => {
    return (
        <div className='flex h-8 w-8 items-center justify-center'>
            {children}
        </div>
    );
};

export default Image;
