import { AppDispatch } from '@redux/Store';
import api from '@redux/Api/Api';
import { setData, setLoading } from '@redux/Slice/MapSlice';

export const FetchRegionData = async (
    dispatch: AppDispatch,
    region: string
) => {
    try {
        dispatch(setLoading(true));
        const response = await api.post('region/get_region', { region });
        dispatch(setData(response.data));
    } catch (error) {
        console.error('Error fetching data:', error);
        dispatch(setData(null));
    } finally {
        dispatch(setLoading(false));
    }
};
