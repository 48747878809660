interface ButtonIconProps {
    icon: any
    children?: string;
    isLoading?: boolean;
    isEnabled?: boolean;
    onClick?: () => void;
    testid?: string;
}

const ButtonIcon: React.FC<ButtonIconProps> = ({
    icon,
    children = '',
    isLoading = false,
    isEnabled = true,
    onClick = () => console.log('no-op'),
    testid,
}) => {
    return (
        <div
            className='flex cursor-pointer flex-col items-center'
            onClick={onClick}
        >
            <div
                className='
                h-dhb w-dhb
                rounded-full 
                bg-ptc dark:bg-ptcd
            '
            >
                <button
                    className='
                    dark:hover:bg-ripple_color-dark group
                    h-dhb w-dhb
                    rounded-full
                    bg-ptc
                    p-2
                    text-sm font-medium 
                    text-pbc hover:bg-ripple_color dark:bg-ptcd dark:text-pbcd
                '
                >
                    {icon}
                </button>
            </div>
            <div className='text-center'>{children}</div>
        </div>
    );
};

export default ButtonIcon;
