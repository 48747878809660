import Marker from './Marker/Marker';
import YandexMap from './YandexMap/YandexMap';

const Map: React.FC = () => {
    return (
        <div className='relative h-screen w-screen'>
            <div className='h-full w-full'>
                <YandexMap />
            </div>
            <Marker />
        </div>
    );
};

export default Map;
