import { useState, useEffect } from 'react';
import MiniPanel from '@res/MiniPanel';
import Tab from '@res/Tab';
import Input from '@res/Input';

interface PendingProps {
    onSelect: (isPending: boolean, date?: string) => void;
}

interface PendingType {
    id: number;
    name: string;
}

const Pending: React.FC<PendingProps> = ({ onSelect }) => {
    const items: PendingType[] = [
        { id: 0, name: 'Как можно скорее' },
        { id: 1, name: 'Предзаказ' },
    ];
    const [selected, setSelected] = useState(items[0]);
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');

    useEffect(() => {
        if (selected.id === 1 && date && time) {
            const receivedDate = new Date(date + ' ' + time);
            const datestamp = receivedDate.toISOString();
            onSelect(true, datestamp);
            return;
        }
        onSelect(false);
        return;
    }, [selected, date, time]);

    const handleSelect = (item: PendingType) => {
        setSelected(item);
    };

    return (
        <MiniPanel>
            <Tab
                items={items}
                selectedItem={selected}
                onSelect={handleSelect}
            />
            {selected.id === 1 && (
                <>
                    <Input type='date' value={date} onChange={setDate} />
                    <Input type='time' value={time} onChange={setTime} />
                </>
            )}
        </MiniPanel>
    );
};

export default Pending;
