import { AppDispatch } from '@redux/Store';
import api from '@redux/Api/Api';
import {
    setActiveOrder,
    setLoading,
    setData,
} from '@redux/Slice/OrderActiveSlice';
import { Location } from '@redux/Slice/MapSlice';
import { OrderActivePayment } from '@enum/OrderActivePayment';

export const FetchAddOrder = async (
    dispatch: AppDispatch,
    location: Location,
    tariff: number,
    price: number,
    payment: OrderActivePayment,
    pending: string | null,
    additionalParams: { [key: string]: any }
) => {
    try {
        dispatch(setLoading(true));
        const response = await api.post('order_active/add', {
            lat: location.lat,
            lon: location.lon,
            address: location.address,
            city: location.city,
            tariff: tariff,
            price: price,
            payment: payment,
            pending: pending,
            additionalParams: additionalParams,
        });
        if (response.status === 200) {
            dispatch(setActiveOrder(true));
            dispatch(setData(response.data));
            return;
        }
        dispatch(setActiveOrder(false));
        dispatch(setData(null));
    } catch (error) {
        console.error('Error fetching data:', error);
        dispatch(setActiveOrder(false));
        dispatch(setData(null));
    } finally {
        dispatch(setLoading(false));
    }
};
