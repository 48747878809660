import { RootState } from '@redux/Store';
import { useSelector, useDispatch } from 'react-redux';
import { setEnabled } from '@redux/Slice/SignInSlice';

import ProgressBar from '@img/ProgressBar';
import { clearAuth } from '@util/CookieManager';
import { setAuth } from '@redux/Slice/UserSlice';

const Right: React.FC = () => {
    const isAuth = useSelector((state: RootState) => state.user.isAuth);
    const isLoading = useSelector((state: RootState) => state.user.isLoading);
    const dispatch = useDispatch();

    const handleSignIn = () => {
        dispatch(setEnabled(true));
    };

    const handleSignOut = () => {
        dispatch(setAuth(false));
        clearAuth();
    };

    if (isLoading) {
        return (
            <div className='flex items-end justify-end text-right'>
                <ProgressBar />
            </div>
        );
    }

    if (!isAuth) {
        return (
            <div className='cursor-pointer' onClick={handleSignIn}>
                Войти
            </div>
        );
    }

    return (
        <div className='cursor-pointer' onClick={handleSignOut}>
            Выйти
        </div>
    );
};

export default Right;
