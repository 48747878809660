import { AppDispatch } from '@redux/Store';
import api from '@redux/Api/Api';
import {
    setActiveOrder,
    setLoading,
    setData,
} from '@redux/Slice/OrderActiveSlice';

export const FetchOrderActiveGet = async (dispatch: AppDispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await api.post('order_active/get', {});
        if (response.status === 200) {
            dispatch(setActiveOrder(true));
            dispatch(setData(response.data));
            return;
        }
        dispatch(setActiveOrder(false));
        dispatch(setData(null));
    } catch (error) {
        // console.error('Error fetching data:', error);
        dispatch(setActiveOrder(false));
        dispatch(setData(null));
    } finally {
        dispatch(setLoading(false));
    }
};
