import { RootState } from '@redux/Store';
import { useSelector } from 'react-redux';

import CellData from './CellData';
import Location from '@img/Location';
import Profile from '@img/Profile';
import Truck from '@img/Truck';
import Price from '@img/Price';

const InfoOrderActive: React.FC = () => {
    const address = useSelector(
        (state: RootState) => state.orderActive.data?.address
    );

    const last_name = useSelector(
        (state: RootState) =>
            state.orderActive.data?.executor?.passport?.last_name
    );
    const first_name = useSelector(
        (state: RootState) =>
            state.orderActive.data?.executor?.passport?.first_name
    );
    const middle_name = useSelector(
        (state: RootState) =>
            state.orderActive.data?.executor?.passport?.middle_name
    );

    const auto = useSelector(
        (state: RootState) => state.orderActive.data?.executor?.auto?.auto
    );
    const auto_number = useSelector(
        (state: RootState) =>
            state.orderActive.data?.executor?.auto?.auto_number
    );

    const price = useSelector(
        (state: RootState) => state.orderActive.data?.price
    );

    return (
        <>
            {address && (
                <CellData iconLeft={<Location />}>Адрес: {address}</CellData>
            )}
            {first_name && middle_name && (
                <CellData iconLeft={<Profile />}>
                    Водитель: {first_name + ' ' + middle_name}
                </CellData>
            )}
            {auto && auto_number && (
                <CellData iconLeft={<Truck />}>
                    Авто: {auto + ' ' + auto_number}
                </CellData>
            )}
            {price && (
                <CellData iconLeft={<Price />}>
                    Конечная стоимость: {Math.round(parseFloat(price))} руб.
                </CellData>
            )}
        </>
    );
};

export default InfoOrderActive;
