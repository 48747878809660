import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Data {}

interface State {
    isAuth: boolean;
    isLoading: boolean;
    token: string;
    data: Data | null;
}

const initialState: State = {
    isAuth: false,
    isLoading: false,
    token: '',
    data: null,
};

const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        setAuth: (state, action: PayloadAction<boolean>) => {
            state.isAuth = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        },
        setData: (state, action: PayloadAction<Data | null>) => {
            state.data = action.payload;
        },
    },
});

export const { setAuth, setLoading, setToken, setData } = userSlice.actions;
export default userSlice.reducer;
