import client from '@client/Client';

import Map from './Map/Map';
import Header from './Header/Header';
import LeftPanel from './LeftPanel/LeftPanel';
import RightPanel from './RightPanel/RightPanel';
import Drawer from '@drawer/Drawer';

const Main: React.FC = () => {
    client();

    return (
        <>
            <Map />
            <Header />
            <LeftPanel />
            <RightPanel />
            <Drawer />
        </>
    );
};

export default Main;
