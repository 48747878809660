import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@redux/Store';
import { FetchOrderActiveGet } from '@redux/Query/FetchOrderActiveGet';
import { TIME_UPDATE_MS } from '@const/Api';
import { setActiveOrder, setData } from '@redux/Slice/OrderActiveSlice';
import { setEnable } from '@redux/Slice/MapSlice';

const UpdateOrderActive = (): void => {
    const isAuth = useSelector((state: RootState) => state.user.isAuth);
    const isOrderActive = useSelector(
        (state: RootState) => state.orderActive.isActiveOrder
    );

    const dispatch = useDispatch();

    const [timeoutId, setTimeoutId] = useState<NodeJS.Timer | null>(null);

    useEffect(() => {
        if (isAuth) {
            if (timeoutId === null) {
                startTimer();
            }
        } else {
            stopTimer();
        }
    }, [isAuth]);

    useEffect(() => {
        if (isAuth && isOrderActive) {
            dispatch(setEnable(false));
        } else {
            dispatch(setEnable(true));
        }
    }, [isOrderActive]);

    const startTimer = () => {
        FetchOrderActiveGet(dispatch);
        const timerId = setInterval(() => {
            FetchOrderActiveGet(dispatch);
        }, TIME_UPDATE_MS);
        setTimeoutId(timerId);
    };

    const stopTimer = () => {
        dispatch(setActiveOrder(false));
        dispatch(setData(null));
        if (timeoutId !== null) {
            clearInterval(timeoutId);
            setTimeoutId(null);
        }
    };
};

export default UpdateOrderActive;
