import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

interface MiniPanelProps {
    children: ReactNode;
    visible?: boolean;
    testid?: string;
}

const MiniPanel: React.FC<MiniPanelProps> = ({
    children,
    visible = true,
    testid,
    ...attrs
}) => {
    const className = `w-full h-full rounded-2xl p-2 space-y-4
    overflow-hidden
    bg-sbc text-ptc 
    dark:bg-sbcd dark:text-ptcd
    text-sm ${visible ? 'visible' : 'collapse'}`;

    return (
        <div className={className} data-testid={testid}>
            {children}
        </div>
    );
};

MiniPanel.propTypes = {
    visible: PropTypes.bool,
    testid: PropTypes.string,
};

MiniPanel.defaultProps = {
    visible: true,
};

export default MiniPanel;
