import { Service } from '@redux/Slice/MapSlice';
import MiniPanel from '@res/MiniPanel';

interface ServiceTabProps {
    items: Service[];
    selectedService: Service | null;
    onSelect: (selectedItem: Service) => void;
}

const ServiceTab: React.FC<ServiceTabProps> = ({
    items,
    selectedService,
    onSelect,
}) => {
    const border = 'border-b-4 rounded-b';

    const handleSelect = (item: Service) => {
        onSelect(item);
    };

    return (
        <MiniPanel>
            <div className='flex flex-row space-x-4 overflow-y-hidden overflow-x-scroll whitespace-nowrap pb-4'>
                {items &&
                    items.map((item) => (
                        <div
                            className={`inline-block ${
                                selectedService === item ? border : ''
                            }`}
                            key={item.id}
                            onClick={() => handleSelect(item)}
                        >
                            {item.name}
                        </div>
                    ))}
            </div>
        </MiniPanel>
    );
};

export default ServiceTab;
