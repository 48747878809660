import Panel from '@res/Panel';
import MobileApp from '@img/MobileApp';

function RightPanel() {
    return (
        <Panel
            className='absolute right-4 top-24 h-auto w-80'
            title='Мобильное приложение'
        >
            <MobileApp />
        </Panel>
    );
}

export default RightPanel;
