import React, { useState, ChangeEvent, ReactNode } from 'react';
import PropTypes from 'prop-types';

interface InputProps {
    placeholder?: string;
    type?: string;
    value?: string;
    svg?: ReactNode;
    maxLength?: number;
    min?: string;
    max?: string;
    onChange?: (value: string) => void;
    onClick?: () => void;
}

const Input: React.FC<InputProps> = ({
    placeholder = '',
    type = 'text',
    value = '',
    svg,
    maxLength = 1024,
    min = '',
    max = '',
    onChange,
    onClick,
    ...attrs
}: InputProps) => {
    const [inputValue, setInputValue] = useState(value || '');

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;

        if (onChange) {
            setInputValue(newValue);
            onChange(newValue);
        }
    };

    return (
        <div className='group relative h-dhb w-auto' onClick={onClick}>
            <div
                className={`
                    pointer-events-none absolute left-3 top-1/2 
                    -mt-3 h-6
                    w-6
                `}
            >
                {svg ? svg : null}
            </div>
            <input
                className={`
                    h-dhb w-full
                    appearance-none rounded-2xl bg-sbc 
                    py-2 leading-6 
                    text-ptc placeholder-stc
                    focus:outline-none focus:ring-2 
                    focus:ring-blue-500 dark:bg-sbcd 
                    dark:text-ptcd dark:placeholder-stcd ${
                        svg ? 'pl-10 ' : 'pl-4 '
                    } pr-4 ring-1 
                    ring-tbc dark:ring-tbcd
                `}
                type={type}
                placeholder={placeholder}
                value={value}
                maxLength={maxLength}
                min={min}
                max={max}
                onChange={handleInputChange}
            />
        </div>
    );
};

Input.propTypes = {
    placeholder: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    svg: PropTypes.element,
    maxLength: PropTypes.number,
    min: PropTypes.string,
    max: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
};

Input.defaultProps = {
    placeholder: '',
    type: 'text',
    value: '',
    svg: null,
    maxLength: 1024,
    min: '',
    max: '',
};

export default Input;
