import { ServiceTariff } from '@redux/Slice/MapSlice';
import CarMini from '@img/CarMini';
import CarStandart from '@img/CarStandart';
import CarBig from '@img/CarBig';

interface TariffTabProps {
    items: ServiceTariff[];
    selectedItem: ServiceTariff | null;
    onSelect: (selectedItem: ServiceTariff) => void;
}

const TariffTab: React.FC<TariffTabProps> = ({
    items,
    selectedItem,
    onSelect,
}) => {
    const selected = 'w-20 rounded-2xl p-1 bg-tbc dark:bg-tbcd';
    const noSelected = 'w-20 rounded-2xl p-1 bg-sbc dark:bg-sbcd';

    const handleSelect = (item: ServiceTariff) => {
        onSelect(item);
    };

    return (
        <div className='flex flex-row justify-between'>
            {items &&
                items.map((item, index) => (
                    <div
                        className={
                            selectedItem === item ? selected : noSelected
                        }
                        key={item.id}
                        onClick={() => handleSelect(item)}
                    >
                        <div className='text-center'>{item.name}</div>
                        <div className='text-center'>
                            ~{item.additional_field}
                        </div>
                        <div className='flex h-16 items-center'>
                            {index === 0 && <CarMini />}
                            {index === 1 && <CarStandart />}
                            {index > 1 && <CarBig />}
                        </div>
                        <div className='text-center'>Цена от:</div>
                        <div className='text-center'>
                            {Math.round(parseFloat(item.price)) + ' руб.'}
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default TariffTab;
