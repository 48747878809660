import MiniPanel from '@res/MiniPanel';
import Image from '@res/Image';
import InfoOrderActive from '@res/InfoOrderActive';
import ButtonIcon from '@res/ButtonIcon';

import Question from '@img/Question';
import ProgressBar from '@img/ProgressBar';
import House from '@img/House';
import Walk from '@img/Walk';
import { useState } from 'react';
import { FetchGoOut } from '@redux/Query/FetchGoOut';

const OnSiteExecutor: React.FC = () => {
    const [isDispayGo, setDispayGo] = useState<boolean>(true);
    const handleGoOut = () => {
        FetchGoOut();
        setDispayGo(false);
    };

    return (
        <div className='space-y-4'>
            <MiniPanel>
                <div className='flex justify-around'>
                    <Image>
                        <Question />
                    </Image>
                    <Image>
                        <ProgressBar />
                    </Image>
                    <Image>
                        <House />
                    </Image>
                </div>
            </MiniPanel>
            <InfoOrderActive />
            <MiniPanel>
                <div className='flex flex-row justify-evenly'>
                    {isDispayGo && (
                        <ButtonIcon icon={<Walk />} onClick={handleGoOut}>
                            Уже выхожу
                        </ButtonIcon>
                    )}
                </div>
            </MiniPanel>
        </div>
    );
};

export default OnSiteExecutor;
