import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum OtpMethods {
    SMS = 'sms',
    CALL = 'call',
}

interface Data {}

interface State {
    isEnabled: boolean;
    isLoading: boolean;
    phone: string;
    otpCode: string;
    getMethod: OtpMethods;
    isOtpSending: boolean;
    timeoutOtpCode: number;
    data: Data | null;
}

const initialState: State = {
    isEnabled: false,
    isLoading: false,
    phone: '',
    otpCode: '',
    getMethod: OtpMethods.SMS,
    isOtpSending: false,
    timeoutOtpCode: 0,
    data: {},
};

const signInSlice = createSlice({
    name: 'signInSlice',
    initialState,
    reducers: {
        setEnabled: (state: State, action: PayloadAction<boolean>) => {
            state.isEnabled = action.payload;
        },
        setLoading: (state: State, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setPhone: (state: State, action: PayloadAction<string>) => {
            state.phone = action.payload;
        },
        setOtpCode: (state: State, action: PayloadAction<string>) => {
            state.otpCode = action.payload;
        },
        setNextMethod: (state: State) => {
            state.getMethod =
                state.getMethod === OtpMethods.SMS
                    ? OtpMethods.CALL
                    : OtpMethods.SMS;
        },
        setOtpSending: (state: State, action: PayloadAction<boolean>) => {
            state.isOtpSending = action.payload;
        },
        setTimeoutOtpCode: (state: State, action: PayloadAction<number>) => {
            state.timeoutOtpCode = action.payload;
        },
        setData: (state: State, action: PayloadAction<Data | null>) => {
            state.data = action.payload;
        },
    },
});

export const {
    setEnabled,
    setLoading,
    setPhone,
    setOtpCode,
    setNextMethod,
    setOtpSending,
    setTimeoutOtpCode,
    setData,
} = signInSlice.actions;
export default signInSlice.reducer;
