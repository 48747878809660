interface TabProps<T> {
    items: T[];
    selectedItem: T | null;
    onSelect: (selectedItem: T) => void;
}

const Tab = <T extends { id: number; name: string }>({
    items,
    selectedItem,
    onSelect,
}: TabProps<T>) => {
    const border = 'border-b-4 rounded-b';

    const handleSelect = (item: T) => {
        onSelect(item);
    };

    return (
        <div className=''>
            <div className='flex flex-row justify-around'>
                {items &&
                    items.map((item) => (
                        <div
                            className={`${
                                selectedItem && selectedItem.id === item.id
                                    ? border
                                    : ''
                            }`}
                            key={item.id}
                            onClick={() => handleSelect(item)}
                        >
                            {item.name}
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default Tab;
