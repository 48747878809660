import Logo from '@img/Logo';

function Left() {
    return (
        <div>
            <Logo />
        </div>
    );
}

export default Left;
