import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/Store';
import { useDispatch } from 'react-redux';
import { setEnabled } from '@redux/Slice/SignInSlice';
import { Service, ServiceTariff } from '@redux/Slice/MapSlice';

import { OrderActiveQuery } from '@enum/OrderActiveQuery';
import { OrderActivePayment } from '@enum/OrderActivePayment';

import { FetchAddOrder } from '@redux/Query/FetchAddOrder';

import Button from '@res/Button';
import Input from '@res/Input';
import ServiceTab from '@res/ServiceTab';
import TariffTab from '@res/TariffTab';
import Location from '@img/Location';
import Comment from '@img/Comment';
import Meters from '@res/Meters';
import Pending from '@res/Pending';
import ButtonIcon from '@res/ButtonIcon';
import Cash from '@img/Cash';
import CreditCard from '@img/CreditCard';

const CreateOrder: React.FC = () => {
    const isAuth = useSelector((state: RootState) => state.user.isAuth);
    const isLoadingAddress = useSelector(
        (state: RootState) => state.map.isLoading
    );
    const location = useSelector((state: RootState) => state.map.location);
    const data = useSelector((state: RootState) => state.map.data);
    const isLoadingOrder = useSelector(
        (state: RootState) => state.orderActive.isLoading
    );

    const [service, setService] = useState<Service | null>(null);
    const [tariff, setTariff] = useState<ServiceTariff | null>(null);
    const [comment, setComment] = useState<string>('');
    const [pending, setPending] = useState<string | null>(null);
    const [price, setPrice] = useState<number>(0);
    const [payment, setPayment] = useState<OrderActivePayment>(
        OrderActivePayment.CASH
    );

    const [meters, setMeters] = useState<number>(0);

    const dispatch = useDispatch();

    useEffect(() => {
        setService(null);
        setTariff(null);
    }, [data]);

    useEffect(() => {
        if (tariff) {
            const sum: number[] = [];
            if (getRequestParameter(OrderActiveQuery.METERS)) {
                sum.push(calculatingMetersPrice());
            }
            calculatingFinalPrice(sum);
        }
    }, [meters, tariff]);

    const getRequestParameter = (key: OrderActiveQuery) => {
        return service?.request_parameters?.find(
            (param) => param.query === key
        );
    };

    const handleSelectService = (item: Service) => {
        if (service !== item) {
            setService(item);
            setTariff(null);
        }
    };

    const handleSelectTariff = (item: ServiceTariff) => {
        if (tariff !== item) {
            setTariff(item);
        }
    };

    const handleClickAddress = () => {};

    const handlePending = (isPending: boolean, date?: string) => {
        if (isPending && date) {
            setPending(date);
            return;
        }
        setPending(null);
    };

    const handleChangePayment = () => {
        if (payment === OrderActivePayment.CASH) {
            setPayment(OrderActivePayment.CREDIT_CARD);
        } else {
            setPayment(OrderActivePayment.CASH);
        }
    };

    const handleCreateOrder = () => {
        if (!isAuth) {
            dispatch(setEnabled(true));
            return;
        }
        if (service && tariff && location) {
            const additionalParams: { meters?: number } = {};
            if (getRequestParameter(OrderActiveQuery.METERS)) {
                additionalParams.meters = meters;
            }
            FetchAddOrder(
                dispatch,
                location,
                tariff.id,
                price,
                payment,
                pending,
                additionalParams
            );
        }
    };

    const handleChangeMetterPrice = (meters: number, price: number) => {
        setMeters(meters);
    };

    const calculatingMetersPrice = () => {
        if (service && tariff && service.request_parameters) {
            var min = -1,
                max = -1,
                delta = -1,
                price = -1;
            service.parameters.map((parameter) => {
                if (parameter.tag === 'meter_min') {
                    min = parameter.value;
                }
                if (parameter.tag === 'meter_max') {
                    max = parameter.value;
                }
                if (parameter.tag === 'meter_delta') {
                    delta = parameter.value;
                }
                if (parameter.tag === 'meter_price') {
                    price = parameter.value;
                }
            });
            const result = ((meters - min) / delta) * price;
            return result;
        }
        return 0;
    };

    const calculatingFinalPrice = (numbers?: number[]) => {
        if (tariff) {
            var sum = Math.round(parseFloat(tariff?.price));
            if (sum > 0) {
                if (numbers) {
                    sum = sum + numbers.reduce((acc, curr) => acc + curr, 0);
                }
                setPrice(sum);
                return;
            }
        }

        setPrice(0);
        return;
    };

    const fillParameter = () => {
        const parameters: JSX.Element[] = [];
        if (service) {
            service.request_parameters.map((param) => {
                switch (param.query) {
                    case 'meters':
                        const meters = fillMeters();
                        if (meters) {
                            parameters.push(meters);
                        }
                        break;
                }
            });
        }
        return parameters;
    };

    const fillMeters = () => {
        if (service && tariff && service.request_parameters) {
            var min = -1,
                max = -1,
                delta = -1,
                price = -1;
            service.parameters.map((parameter) => {
                if (parameter.tag === 'meter_min') {
                    min = parameter.value;
                }
                if (parameter.tag === 'meter_max') {
                    max = parameter.value;
                }
                if (parameter.tag === 'meter_delta') {
                    delta = parameter.value;
                }
                if (parameter.tag === 'meter_price') {
                    price = parameter.value;
                }
            });
            if (min !== -1 && max !== -1 && delta !== -1 && price !== -1) {
                return (
                    <Meters
                        min={min}
                        max={max}
                        delta={delta}
                        deltaPrice={price}
                        onChange={handleChangeMetterPrice}
                    />
                );
            }
        }
        return null;
    };

    return (
        <>
            <ServiceTab
                items={data?.services ? data.services : []}
                selectedService={service}
                onSelect={handleSelectService}
            />
            <TariffTab
                items={service?.tariffs ? service.tariffs : []}
                selectedItem={tariff}
                onSelect={handleSelectTariff}
            />
            {tariff && (
                <Input
                    placeholder={'Адрес'}
                    value={location.address}
                    svg={<Location />}
                    onClick={handleClickAddress}
                />
            )}
            {tariff && (
                <Input
                    placeholder={'Комментарий'}
                    value={comment}
                    onChange={setComment}
                    svg={<Comment />}
                />
            )}
            {fillParameter()}
            {tariff && <Pending onSelect={handlePending} />}
            {tariff && (
                <div className='flex flex-row space-x-4'>
                    <ButtonIcon
                        onClick={handleChangePayment}
                        icon={
                            payment === OrderActivePayment.CASH ? (
                                <Cash />
                            ) : (
                                <CreditCard />
                            )
                        }
                    />
                    <Button
                        onClick={handleCreateOrder}
                        isLoading={isLoadingAddress || isLoadingOrder}
                    >
                        {'Заказать за ' + price.toString()}
                    </Button>
                </div>
            )}
        </>
    );
};

export default CreateOrder;
