import React, { Suspense } from 'react';
import ProgressBar from '@img/ProgressBar';

const Image = React.lazy(() => import('../Svg/House'));

const ImageWrapperWithFallback = () => (
    <Suspense fallback={<ProgressBar />}>
        <Image />
    </Suspense>
);

export default ImageWrapperWithFallback;
