import { AppDispatch } from '@redux/Store';
import api from '@redux/Api/Api';
import { setEnabled } from '@redux/Slice/SignInSlice';
import { setAuth, setLoading, setData } from '@redux/Slice/UserSlice';

export const FetchSignIn = async (dispatch: AppDispatch, token: string) => {
    try {
        dispatch(setLoading(true));
        const response = await api.post('user/signin', {
            token,
            device: 'web',
        });
        if (response.status === 200) {
            dispatch(setAuth(true));
            dispatch(setData(response.data));
            dispatch(setEnabled(false));
        } else {
            dispatch(setAuth(false));
            dispatch(setData(null));
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        dispatch(setData(null));
    } finally {
        dispatch(setLoading(false));
    }
};
