import { useState, useEffect } from 'react';
import MiniPanel from '@res/MiniPanel';
import ButtonIcon from '@res/ButtonIcon';
import Minus from '@img/Minus';
import Plus from '@img/Plus';

interface MetersProps {
    min: number;
    max: number;
    delta: number;
    deltaPrice: number;
    onChange: (meters: number, price: number) => void;
}

const Meters: React.FC<MetersProps> = ({
    min,
    max,
    delta,
    deltaPrice,
    onChange,
}) => {
    const [current, setCurrent] = useState(min);
    const [price, setPrice] = useState(0);

    const handlePlus = () => {
        if (current + delta <= max) {
            setCurrent(current + delta);
        }
    };

    const handleMinus = () => {
        if (current - delta >= min) {
            setCurrent(current - delta);
        }
    };

    useEffect(() => {
        const result = ((current - min) / delta) * deltaPrice;
        onChange(current, result);
    }, [current]);

    return (
        <MiniPanel>
            <div className='flex flex-row justify-around'>
                <div>
                    <div className='text-center'>Длина шланга:</div>
                    <div className='text-center'>(Бесплатно: м.)</div>
                </div>
                <div>
                    <div className='flex flex-row justify-around'>
                        <ButtonIcon icon={<Minus />} onClick={handleMinus} />
                        <div className='pl-1 pr-1'>
                            <div className='text-center'>{current}</div>
                            <div className='text-center'>метров</div>
                        </div>
                        <ButtonIcon icon={<Plus />} onClick={handlePlus} />
                    </div>
                </div>
            </div>
        </MiniPanel>
    );
};

export default Meters;
