import { FC } from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '@img/ProgressBar';

interface ButtonProps {
    children?: string;
    isLoading?: boolean;
    isEnabled?: boolean;
    onClick?: () => void;
    testid?: string;
}

const Button: FC<ButtonProps> = ({
    children = '',
    isLoading = false,
    isEnabled = true,
    onClick = () => console.log('no-op'),
    testid,
}) => {
    const click = () => {
        if (isLoading || !isEnabled) {
            return;
        }
        onClick();
    };

    const loading = () => {
        return <ProgressBar />;
    };

    return (
        <div
            className={`
                h-dhb w-full
                rounded-full 
                bg-ptc dark:bg-ptcd
            `}
        >
            <button
                className={`
                    dark:hover:bg-ripple_color-dark group 
                    h-dhb w-full rounded-full
                    bg-ptc 
                    py-2 pl-2 pr-3
                    text-sm font-medium
                    text-pbc hover:bg-ripple_color 
                    dark:bg-ptcd dark:text-pbcd 
                `}
                onClick={click}
                data-testid={testid}
            >
                <div className='flex justify-center'>
                    {isLoading ? loading() : children}
                </div>
            </button>
        </div>
    );
};

Button.propTypes = {
    children: PropTypes.string,
    isLoading: PropTypes.bool,
    isEnabled: PropTypes.bool,
    onClick: PropTypes.func,
    testid: PropTypes.string,
};

Button.defaultProps = {
    children: '',
    isLoading: false,
    isEnabled: true,
    onClick: () => {
        console.log('no-op');
    },
};

export default Button;
