import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Location {
    lat: number;
    lon: number;
    address: string;
    city: string;
}

export interface Search {
    query: string;
    results: SearchResult[] | [];
}

export interface SearchResult {}

export interface ServiceParameter {
    name: string;
    tag: string;
    value: number;
}

export interface ServiceRequestParameter {
    description: string;
    name: string;
    query: string;
}

export interface ServiceTariff {
    additional_field: number;
    id: number;
    name: string;
    price: string;
    service: string;
}

export interface Service {
    id: number;
    name: string;
    parameters: ServiceParameter[];
    request_parameters: ServiceRequestParameter[];
    tariffs: ServiceTariff[];
}

export interface Region {
    id: number;
    name: string;
    services: Service[];
}

interface State {
    isEnable: boolean;
    isLoading: boolean;
    location: Location;
    search: Search;
    data: Region | null;
}

const initialState: State = {
    isEnable: true,
    isLoading: false,
    location: { lat: 0, lon: 0, address: '', city: '' },
    search: { query: '', results: [] },
    data: null,
};

const mapSlice = createSlice({
    name: 'mapSlice',
    initialState,
    reducers: {
        setEnable: (state, action: PayloadAction<boolean>) => {
            state.isEnable = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setLocation: (state, action: PayloadAction<Location>) => {
            state.location = action.payload;
        },
        setSearchQuery: (state, action: PayloadAction<string>) => {
            state.search.query = action.payload;
        },
        setSearchResults: (state, action: PayloadAction<SearchResult[]>) => {
            state.search.results = action.payload;
        },
        setData: (state, action: PayloadAction<Region | null>) => {
            state.data = action.payload;
        },
    },
});

export const { setEnable, setLoading, setLocation, setData } = mapSlice.actions;
export default mapSlice.reducer;
