import React, { ReactNode } from 'react';

interface CellDataProps {
    children: ReactNode;
    iconLeft?: ReactNode;
    iconRight?: ReactNode;
    onClick?: () => void;
    testid?: string;
}

const CellData: React.FC<CellDataProps> = ({
    children,
    iconLeft,
    iconRight,
    onClick,
    testid,
}) => {
    return (
        <div
            className='flex h-dhb w-full 
        flex-row
        content-center items-center 
        overflow-hidden rounded-2xl
        bg-sbc text-ptc dark:bg-sbcd dark:text-ptcd'
        >
            {iconLeft && (
                <div className='ml-2 flex h-8 w-8 items-center'>{iconLeft}</div>
            )}
            <div className='m-2 w-full'>{children}</div>
            {iconRight && (
                <div className='mr-2 flex h-8 w-8 items-center'>
                    {iconRight}
                </div>
            )}
        </div>
    );
};

export default CellData;
