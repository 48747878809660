import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

interface PanelProps {
    className?: string;
    title?: string;
    children: ReactNode;
}

const Panel: React.FC<PanelProps> = ({
    className,
    title,
    children,
    ...attrs
}) => {
    const header = (
        <div
            className='flex h-10 items-center justify-center 
        border-b border-tbc shadow-2xl 
        shadow-tbc dark:border-tbcd dark:shadow-tbcd'
        >
            {title}
        </div>
    );

    return (
        <div className={className} {...attrs}>
            <div
                className='flex h-full w-full flex-col
                justify-center overflow-hidden rounded-2xl
            bg-pbc
            text-sm 
            text-ptc shadow-2xl
            dark:bg-pbcd dark:text-ptcd'
            >
                {title ? header : ''}
                {children}
            </div>
        </div>
    );
};

Panel.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.node,
};

export default Panel;
