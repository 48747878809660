import { useDispatch } from 'react-redux';
import { getAuth } from '@util/CookieManager';
import { FetchSignIn } from '@redux/Query/FetchSignIn';

const SignInUser = (): void => {
    const dispatch = useDispatch();
    const token = getAuth();

    if (token) {
        FetchSignIn(dispatch, token);
    }
};

export default SignInUser;
