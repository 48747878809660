import React, { ReactNode, Suspense, lazy, startTransition } from 'react';

import { RootState } from '@redux/Store';
import { useSelector } from 'react-redux';

const SignIn = lazy(() => import('@drawer/SignIn'));
const Cancel = lazy(() => import('@drawer/Cancel'));

const Drawer: React.FC = () => {
    const isEnableSignIn = useSelector(
        (state: RootState) => state.signIn.isEnabled
    );
    const isEnableCancel = useSelector(
        (state: RootState) => state.orderActive.cancel.isEnable
    );

    const loadDrawer = (children: ReactNode) => {
        return <Suspense>{children}</Suspense>;
    };

    console.log(isEnableSignIn);
    console.log(isEnableCancel);

    if (isEnableSignIn) {
        return loadDrawer(<SignIn />);
    }

    if (isEnableCancel) {
        return loadDrawer(<Cancel />);
    }

    return null;
};

export default Drawer;
