import axios from 'axios';

import { HOST } from '@const/Api';

const baseURL = HOST;

const api = axios.create({
    baseURL,
    timeout: 15000,
    withCredentials: true,
});

export default api;
