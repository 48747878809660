import Panel from '@res/Panel';
import Left from './Left/Left';
import Center from './Center/Center';
import Right from './Right/Right';

const Header: React.FC = () => {
    return (
        <Panel className='absolute left-0 right-0 top-0 m-4 h-16 w-auto'>
            <div className='m-4 flex flex-row items-center justify-between'>
                <div className='flex-1 justify-start text-left'>
                    <Left />
                </div>
                <div className='flex-1 justify-center text-center'>
                    <Center />
                </div>
                <div className='flex-1 justify-end text-right'>
                    <Right />
                </div>
            </div>
        </Panel>
    );
};

export default Header;
