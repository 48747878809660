import { useSelector } from 'react-redux';
import { RootState } from '@redux/Store';

const Marker: React.FC = () => {
    const isEnable = useSelector((state: RootState) => state.map.isEnable);

    if (!isEnable) {
        return null;
    }

    return (
        <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform'>
            <div className='relative'>
                <div className='h-4 w-4 rounded-full bg-red-500'></div>
                <div
                    className='absolute w-0.5 bg-red-500'
                    style={{
                        height: '80%',
                        top: '100%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                    }}
                ></div>
            </div>
        </div>
    );
};

export default Marker;
