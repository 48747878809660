import { useDispatch } from 'react-redux';
import { setCancelEnable } from '@redux/Slice/OrderActiveSlice';

import MiniPanel from '@res/MiniPanel';
import Image from '@res/Image';
import InfoOrderActive from '@res/InfoOrderActive';
import ButtonIcon from '@res/ButtonIcon';

import CarStandart from '@img/CarStandart';
import ProgressBar from '@img/ProgressBar';
import House from '@img/House';
import Cancel from '@img/Cancel';

const OnWayExecutor: React.FC = () => {
    const dispatch = useDispatch();

    const hancleCancel = () => {
        dispatch(setCancelEnable(true));
    };

    return (
        <div className='space-y-4'>
            <MiniPanel>
                <div className='flex justify-around'>
                    <Image>
                        <CarStandart />
                    </Image>
                    <Image>
                        <ProgressBar />
                    </Image>
                    <Image>
                        <House />
                    </Image>
                </div>
            </MiniPanel>
            <InfoOrderActive />
            <MiniPanel>
                <ButtonIcon icon={<Cancel />} onClick={hancleCancel}>
                    Отменить заявку
                </ButtonIcon>
            </MiniPanel>
        </div>
    );
};

export default OnWayExecutor;
