export const getAuth = (): string | null => {
    return getCookie('account');
};

export const clearAuth = (): void => {
    setCookie('account', null);
};

const getCookie = (name: string): string | null => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        const result = parts.pop()?.split(';').shift();
        return result !== undefined ? result : null;
    }
    return null;
};

const setCookie = (name: string, value: string | null): void => {
    if (value === null) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    } else {
        document.cookie = `${name}=${value}; path=/`;
    }
};
