function MobileApp() {
    return (
        <img
            src='http://fastpick.ucoz.ru//_ph/13/2/435666540.webp'
            loading='lazy'
        />
    );
}

export default MobileApp;
