import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@redux/Store';
import {
    setEnable,
    setLoading,
    setLocation,
    setData,
    Location,
} from '@redux/Slice/MapSlice';
import { FetchRegionData } from '@redux/Query/FetchRegionData';
import {
    YMaps,
    Map,
    GeolocationControl,
    ZoomControl,
    Placemark,
} from '@pbe/react-yandex-maps';

import { LAT_DEFAULT, LON_DEFAULT } from '@const/Map';

const YandexMap: React.FC = () => {
    const isEnable = useSelector((state: RootState) => state.map.isEnable);
    const isLoading = useSelector((state: RootState) => state.map.isLoading);
    const location = useSelector((state: RootState) => state.map.location);
    const data = useSelector((state: RootState) => state.map.data);

    const dispatch = useDispatch();

    const mapRef = useRef<any>(null);
    const [ymap, setYMap] = useState<any>(null);
    const [lat, setLat] = useState<number>(LAT_DEFAULT);
    const [lon, setLon] = useState<number>(LON_DEFAULT);

    const initMap = (ymapInstance: any, mapRefInstance: any) => {
        if (ymapInstance) setYMap(ymapInstance);
        if (mapRefInstance) mapRef.current = mapRefInstance;
        if (!ymapInstance || !mapRefInstance) return;

        updateCenter(mapRef.current.getCenter());
        mapRef.current.events.add('boundschange', handleBoundsChange);
    };

    useEffect(() => {
        if (ymap && mapRef.current) {
            initMap(ymap, mapRef.current);
        }
    }, [ymap]);

    useEffect(() => {
        updateRegion();
    }, [location.city]);

    const handleBoundsChange = (event: any) => {
        const newCenter = event.get('newCenter');
        updateCenter(newCenter);
    };

    const getAddressQuery = async (location: number[]) => {
        const response = await ymap.geocode(location);
        const address =
            response.geoObjects.get(0).getThoroughfare() +
            ' ' +
            response.geoObjects.get(0).getPremiseNumber();
        const city = response.geoObjects.get(0).getLocalities()[0];
        dispatch(
            setLocation({
                lat: convertArrayToLocation(location).lat,
                lon: convertArrayToLocation(location).lon,
                address: address,
                city: city,
            })
        );
    };

    const getRegionQuery = async () => {
        await FetchRegionData(dispatch, location.city);
    };

    const updateCenter = (location: number[]) => {
        getAddressQuery(location);
    };

    const updateRegion = async () => {
        if (!isEnable) return;
        if (!!location.city) {
            getRegionQuery();
        }
    };

    const convertLocationToArray = (lat: number, lon: number) => {
        return [lat, lon];
    };

    const convertArrayToLocation = (array: number[]) => {
        return { lat: array[0], lon: array[1] };
    };

    interface YMapsQuery {
        lang?:
            | 'ru_RU'
            | 'tr_TR'
            | 'en_US'
            | 'en_RU'
            | 'ru_UA'
            | 'uk_UA'
            | undefined;
        load?: string;
        apikey?: string;
    }

    const query: YMapsQuery = {
        lang: 'ru_RU',
        load: 'geocode,search',
        apikey: 'eefb911c-223f-422c-a900-8eea3d6ce55c',
    };

    return (
        <YMaps query={query}>
            <Map
                width='100%'
                height='100%'
                defaultState={{
                    center: convertLocationToArray(lat, lon),
                    zoom: 14,
                }}
                instanceRef={(ref) => initMap(null, ref)}
                onLoad={(ymapInstance) => initMap(ymapInstance, null)}
            >
                <GeolocationControl
                    options={{ position: { bottom: '32px', right: '32px' } }}
                />
                <ZoomControl
                    options={{ position: { bottom: '92px', right: '32px' } }}
                />
            </Map>
        </YMaps>
    );
};

export default YandexMap;
