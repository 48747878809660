import { configureStore } from '@reduxjs/toolkit';
import MapSlice from './Slice/MapSlice';
import UserSlice from './Slice/UserSlice';
import SignInSlice from './Slice/SignInSlice';
import OrderActiveSlice from './Slice/OrderActiveSlice';

const store = configureStore({
    reducer: {
        map: MapSlice,
        signIn: SignInSlice,
        user: UserSlice,
        orderActive: OrderActiveSlice,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
