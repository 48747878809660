import React, { useState } from 'react';

interface RatingProps {
    value: number;
    onChange: (value: number) => void;
}

const Rating: React.FC<RatingProps> = ({ value, onChange }) => {
    const [hoverValue, setHoverValue] = useState<number | null>(null);

    const handleStarClick = (selectedValue: number) => {
        onChange(selectedValue);
    };

    const handleStarHover = (hoveredValue: number) => {
        setHoverValue(hoveredValue);
    };

    const handleMouseLeave = () => {
        setHoverValue(null);
    };

    const stars = Array.from({ length: 5 }, (_, index) => index + 1);

    return (
        <div className='flex items-center'>
            {stars.map((star) => (
                <span
                    key={star}
                    className={`cursor-pointer text-2xl ${
                        star <= (hoverValue || value)
                            ? 'text-rating_color'
                            : 'text-rating_background'
                    }`}
                    onClick={() => handleStarClick(star)}
                    onMouseEnter={() => handleStarHover(star)}
                    onMouseLeave={handleMouseLeave}
                >
                    &#9733;
                </span>
            ))}
        </div>
    );
};

export default Rating;
